// Import our Bootstrap extensions
// Import all plugins
import * as bootstrap from 'bootstrap';

import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'
import Prism from 'prismjs';
import './vendor';

window.Alpine = Alpine;
Alpine.plugin(persist);

Prism.languages.java.install = {
    pattern: /\binstall\b/,  // Matches the word 'install'
    alias: 'custom-install'  // Adds a custom alias for styling
};


document.addEventListener('alpine:init', () => {
    Alpine.data('testboxData', function () {
        return {
            isDark: this.$persist( true ).as( 'testbox-darkmode' ),
            isOpenMenu: false,
            scrolled: false,

            init() {},

            getCustomFieldValue(arr, name) {
                return arr.reduce((acc, cur) => {
                    if (cur.key == name) {
                        acc = cur.value;
                    }
                    return acc;
                }, "");
            },

            get isHomePage(){
                let currentURL = window.location.pathname.replace( "/", "" );
                return currentURL.length ? false : true
            }
        }
    })
})

Alpine.start();
